import { login } from '@/api/admin'
import axios from '@/api/axios-auth'

const auth = {
  namespaced: true,
  state: () => ({
    status: {
      jwtToken: {
        initialized: false,
        refreshing: false,
        error: false,
        errorCode: 0,
      },
    },
    jwtToken: '',
  }),
  getters: {
    isAuthenticated: (state) => !!state.jwtToken,
  },
  actions: {
    logIn ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'jwtToken')
        commit('SET_ERROR_FALSE', 'jwtToken')
        login(data.username, data.password)
          .then((jwtToken) => {
            commit('SET_INITIALIZED_TRUE', 'jwtToken')
            commit('SET_REFRESHING_FALSE', 'jwtToken')
            commit('SET_JWT_TOKEN', jwtToken)
            resolve()
          })
          .catch((err) => {
            commit('SET_REFRESHING_FALSE', 'jwtToken')
            commit('SET_ERROR_TRUE', 'jwtToken')
            commit('REMOVE_JWT_TOKEN')
            reject(err)
          })
      })
    },
    logOut ({ commit }) {
      localStorage.setItem('gitfCardLoginToken', "");
      return new Promise((resolve, reject) => {
        commit('SET_INITIALIZED_FALSE', 'jwtToken')
        commit('REMOVE_JWT_TOKEN')
        resolve()
      })
    },
  },
  mutations: {
    SET_INITIALIZED_TRUE: (state, dataName) => {
      state.status[dataName].initialized = true
    },
    SET_INITIALIZED_FALSE: (state, dataName) => {
      state.status[dataName].initialized = false
    },
    SET_REFRESHING_TRUE: (state, dataName) => {
      state.status[dataName].refreshing = true
    },
    SET_REFRESHING_FALSE: (state, dataName) => {
      state.status[dataName].refreshing = false
    },
    SET_ERROR_TRUE: (state, dataName) => {
      state.status[dataName].error = true
    },
    SET_ERROR_FALSE: (state, dataName) => {
      state.status[dataName].error = false
    },
    SET_JWT_TOKEN: (state, payload) => {
      axios.defaults.headers.common.Authorization = 'Bearer ' + payload
      state.jwtToken = payload
    },
    REMOVE_JWT_TOKEN: (state) => {
      state.jwtToken = ''
    },
  },
}

export default auth
